@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap'); /* Merriweather for a formal look */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .h-header {
    height: 84px;
  }
  .h-section {
    height: calc(100vh - 84px);
  }
}
@layer base {
  :root {
    --header-height: 52px;
    --footer-height: 82px;
    --background: 0 0% 100%;
    --background-darker: 0, 0%, 95%;
    --background-lighter: 0, 0%, 99%;
    --background-lightest: 0, 0%, 98%;
    --primary: #3d88ed;
    --primary-50: #f6f9fe;
    --primary-100: #bdc9f4;
    --primary-200: #91a5ed;
    --primary-300: #6581e6;
    --primary-400: #395ddf;
    --primary-500-main: #2043c6;
    --primary-600: #19349a;
    --primary-700: #122670;
    --primary-800: #0b1642;
    --primary-900: #040716;

    --error-200: #f19c95;
    --error-300: #eb7066;
    --error-400: #e54639;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.arrow {
  animation: bounce 1s infinite;
}
.hover-effect {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}
.hover-effect:hover {
  padding: 0.75rem; /* Same as p-3 */
  border-radius: 0.75rem; /* Same as rounded-xl */
  background-color: var(--primary-700);
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Adjust shadow as needed */
}

.text-outline-hover {
  /* color: transparent;  */
  /* text-shadow: 0 0 5px var(--primary-700), 0 0 10px var(--primary-700); */
  transition: all 0.3s ease-in-out;
}

.text-outline-hover:hover {
  color: var(--primary-700); /* Hiển thị màu chữ khi hover */
  text-shadow: 0 0 15px var(--primary-400), 0 0 20px var(--primary-700); /* Tăng hiệu ứng outline khi hover */
  transform: scale(1.1); /* Tăng kích thước khi hover */
}

html {
  scroll-behavior: smooth; /* Thêm hiệu ứng cuộn mượt */
}

@media (min-width: 768px) {
  .mobile-nav {
    overflow-y: auto; /* Hoặc bạn có thể đặt overflow-y: scroll nếu cần */
  }
}

.korea-flag-container {
  border: 2px solid #333; /* Chọn màu border bạn muốn */
  border-radius: 50%; /* Đảm bảo border có dạng tròn */
  padding: 5px; /* Điều chỉnh khoảng cách từ border đến hình ảnh */
}

@media (min-width: 1024px) {
  .korea-flag-container {
    border: 3px solid #333; /* Border khi màn hình lg */
  }
}
